{
  "name": "intorqa-ui",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "build:saas": "ng build portal",
    "build:console": "ng build console",
    "build:saas:compress": "ng build portal --configuration production --verbose --aot --delete-output-path --output-hashing all --output-path .build/dist/intorqa-saas && gzipper c .build/dist/intorqa-saas --include js,css --output-file-format [filename].[ext] .build/dist/intorqa-saas",
    "release:saas": "concurrently \"npm run generate:svg-sprite\" \"ng build portal --configuration production --verbose --aot --delete-output-path --output-hashing all --output-path .build/dist/intorqa-saas\"",
    "release:console": "concurrently \"npm run generate:svg-sprite\" \"ng build console --configuration production --verbose --aot --delete-output-path --output-hashing all --output-path .build/dist/intorqa-console\"",
    "test": "ng test",
    "e2e": "ng e2e",
    "portal:local": "concurrently \"ng serve portal --configuration local\"",
    "portal:production": "concurrently \"ng serve portal --configuration production\"",
    "portal:local-dev": "concurrently \"ng serve portal --configuration local-dev\"",
    "portal:local-test": "concurrently \"ng serve portal --configuration local-test\"",
    "portal:local-prod": "concurrently \"ng serve portal --configuration local-prod\"",
    "portal:dev-staging": "concurrently \"ng serve portal --configuration dev-staging\"",
    "console": "concurrently \"ng serve console\"",
    "console:local": "ng serve console --configuration local",
    "console:prod": "ng serve console --configuration production",
    "console:local-dev": "ng serve console --configuration local-dev",
    "console:local-prod": "ng serve console --configuration local-prod",
    "console:local-test": "ng serve console --configuration local-test",
    "docs:json": "compodoc -p ./tsconfig.json -e json -d .",
    "clean": "rm -rf .build/dist",
    "generate:svg-sprite": "svg2sprite ./projects/design/src/lib/assets/icons ./projects/design/src/lib/assets/icons/sprite.svg --stripAttrs fill --stripAttrs stroke --stripAttrs id"
  },
  "private": true,
  "dependencies": {
    "@angular-builders/jest": "^18.0.0",
    "@angular/animations": "^18.2.2",
    "@angular/cdk": "^18.2.2",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/material": "^18.2.2",
    "@angular/material-moment-adapter": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@angular/youtube-player": "^18.2.2",
    "@carrotsearch/foamtree": "https://secure.carrotsearch.com/download/foamtree/3.5.0/npm?A1f8b08000000000000036d90c94ec3301445f77cc5a32b90ea0cce8c42ba2862ec0615c4dab19f69a4c42e8e5be8dfe3a64d5524bcbb3ed7efd82edb86a3ea11b668fa46abdb49e8d14975010065d3f71b34d59c19a32d2c9119be82dee35ee91fd150bb2404ee98c51ea46e5bfd7d034290ae233bb7e04ab0dd143aadec6a0a3b37e2dab54cc72c70adb6a8ac737a40c8995154342541446840c3a3491c30feac1b837da5d0ddb6f4c738b08e35caa2628a2319c1690e2dfdfff870f0f80138247852569b2f060f466fd6b068bac6a2184816922c81e50a5ba1152cad41b430d7fb37c0033302152cb4726c0a1f73fa08c5f32bbcbf0c0aff8fa35c1b2d36dc1efc671b63de6f29d66175af59f7e63ca53fc4b1ee9ff74fc98d3b89aa72d6379f8ad98d414830e751c1ea2c0de348c81a8b340c30c8c220422ea840996739a5b51f4b96b294e745cc65cdb28087328b641ec501224fc220c9452a7399cdaa5f70b6202736020000Z",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-pro": "^6.6.0",
    "@fortawesome/fontawesome-svg-core": "^6.6.0",
    "@fortawesome/free-brands-svg-icons": "^6.6.0",
    "@fortawesome/pro-regular-svg-icons": "^6.6.0",
    "@katoid/angular-grid-layout": "^2.0.0",
    "@syncfusion/ej2-angular-navigations": "^23.2.4",
    "@syncfusion/ej2-base": "^23.2.4",
    "@types/quill": "^2.0.14",
    "@types/vertx__eventbus-bridge-client.js": "^1.0.0",
    "@vertx/eventbus-bridge-client.js": "^1.0.0",
    "echarts": "^5.2.2",
    "franc": "^6.0.0",
    "html2canvas": "^1.4.1",
    "i": "^0.3.7",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^23.0.6",
    "moment": "^2.29.1",
    "ng-packagr": "^18.2.1",
    "ngx-angular-query-builder": "^18.0.0",
    "ngx-echarts": "17.1",
    "ngx-quill": "^26.0.8",
    "npm": "^10.8.3",
    "parchment": "^3.0.0",
    "posthog-js": "^1.117.2",
    "rxjs": "^7.0.0",
    "sass": "^1.49.0",
    "svg2sprite-cli": "^2.0.1",
    "tslib": "^2.0.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.2",
    "@angular-eslint/eslint-plugin": "^18.3.0",
    "@angular-eslint/eslint-plugin-template": "^18.3.0",
    "@angular-eslint/template-parser": "^18.3.0",
    "@angular/cli": "^18.2.2",
    "@angular/compiler-cli": "^18.2.2",
    "@babel/core": "^7.13.14",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.9.0",
    "@typescript-eslint/eslint-plugin": "^8.4.0",
    "@typescript-eslint/parser": "^8.4.0",
    "babel-loader": "^8.2.2",
    "codelyzer": "^6.0.0",
    "concurrently": "^5.2.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "faker": "^5.1.0",
    "jest": "^29.7.0",
    "prettier": "^3.2.5",
    "tailwindcss": "^3.4.13",
    "ts-node": "~8.3.0",
    "typescript": "~5.5.4"
  }
}
