<form class="flex flex-col w-full" [formGroup]="form">
  <div class="flex items-center p-7 border-b border-border justify-between">
    <div class="flex items-center">
      <fa-icon [icon]="['far', 'bell']" [size]="'2x'"></fa-icon>
      <h1 class="text-28 font-bold pl-4">Alerts</h1>
    </div>
    <itq-mat-button
      [matTooltip]="'Close'"
      [materialStyle]="'basic'"
      [type]="'button'"
      (clickEvent)="onClose()"
    >
      <fa-icon [icon]="['far', 'times']" [size]="'2x'"></fa-icon
    ></itq-mat-button>
  </div>
  <div class="flex flex-1 overflow-hidden">
    <itq-sidebar class="flex-none! p-4" [width]="300">
      <div class="mb-7">
        <itq-input
          [formControlName]="'query'"
          [type]="'text'"
          [placeholder]="'Search alerts...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
      </div>
      <div class="flex mb-2 flex-col">
        <span class="mb-1 text-13 font-medium">TYPE</span>
        <itq-dropdown
          [dataSource]="alertTypesDataSource"
          formControlName="alertType"
          [dataFields]="{ name: 'label', value: 'id' }"
          (changeValue)="onSearch()"
          (clear)="onSearch()"
        ></itq-dropdown>
        <span class="text-13 text-metadata pt-1">Select the alert type</span>
      </div>
      <div class="flex mb-2 flex-col">
        <span class="mb-1 text-13 font-medium">PRIORITY</span>
        <itq-dropdown
          [dataSource]="priorityDataSource"
          formControlName="priority"
          [dataFields]="{ name: 'name', value: 'value' }"
          (changeValue)="onSearch()"
          (clear)="onSearch()"
        ></itq-dropdown>
        <span class="text-13 text-metadata pt-1"> Select the priority</span>
      </div>
      <div class="flex mb-2 flex-col">
        <span class="mb-1 text-13 font-medium">DATE</span>

        <itq-date-range
          class="w-full"
          [dates]="this.initialState?.where"
          (search)="onChangeDates($event)"
        ></itq-date-range>

        <span class="text-13 text-metadata pt-1"> Select date range</span>
      </div>
    </itq-sidebar>
    <div class="flex w-full relative">
      <div class="flex flex-col flex-1 py-4">
        <div class="flex ml-4 mb-4">
          <itq-button-thumbnail
            [formControlName]="'showAll'"
            [dataSource]="buttonDataSource"
            (change)="onSearch()"
          ></itq-button-thumbnail>
          @if (unreadCount > 0) {
            <itq-mat-button
              class="mx-7"
              [type]="'button'"
              [materialStyle]="'stroked'"
              [padding]="Sizes.SM"
              (clickEvent)="onMarkAllRead()"
            >
              <div class="flex items-center">
                <fa-icon [icon]="['far', 'check']"></fa-icon>MARK ALL AS READ
              </div>
            </itq-mat-button>
          }
        </div>
        <div class="flex overflow-hidden">
          <itq-notifications-timeline
            class="flex-1 min-w-[500px]"
            [raisedAlertId]="raisedAlertId"
            [initialState]="initialState"
            [dataSource]="notificationsDataSource"
            (drilldown)="onDrilldown($event.segment, $event.notification)"
            (dataBound)="onDataBound($event)"
          ></itq-notifications-timeline>
        </div>
      </div>

      @if (raisedAlertId && matchesDataSource?.items) {
        <div class="flex relative flex-1 border-border border-l">
          <itq-virtual-table
            class="flex min-w-[500px] flex-1"
            [initialState]="initialState"
            [tableColumns]="tableColumns"
            [theme]="'tile'"
            [headerVisible]="false"
            [dataSource]="matchesDataSource?.items"
            [totalCount]="matchesDataSource?.totalHits"
            (dataBound)="onDataBoundOtherMatches($event)"
          ></itq-virtual-table>
        </div>
      }
    </div>
  </div>
</form>
<ng-template #unreadTemplate> UNREAD ({{ unreadCount || 0 }}) </ng-template>
<ng-template #template let-data>
  <itq-document-item-tile-v2
    [document]="data"
    [form]="form"
    [allowDrilldown]="false"
    (drilldown)="onDrilldownMatches($event)"
    (createProfile)="onCreateProfile($event)"
  ></itq-document-item-tile-v2>
</ng-template>
@if (showLoader) {
  <itq-loader></itq-loader>
}
