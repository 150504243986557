import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  Align,
  AvatarComponent,
  ComponentType,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  FormatNumberPipe,
  GetUsernameInitialsPipe,
  IDropdownItem,
  IconType,
  PillType,
  QueryFilters,
  SearchFieldType,
  SharedService,
  Sizes,
  SvgComponent,
  TableColumn,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ProfileTypeIcon } from '@portal/profiles/enums/profile.enum';
import { MetadataFields } from '@portal/profiles/enums/profiles-metadata.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileList } from '@portal/profiles/models/profile-list';
import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { ProfileMetricsService } from '@portal/profiles/services/vendors-metrics.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { IWidgetData } from '@portal/shared/interfaces/widget.interface';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { Subscription } from 'rxjs';
import { Cheat_Reputation_Map, VISIBLE_COLUNNS } from './const/vendors.const';
import {
  IMetadataColumn,
  IMetadataField,
} from './interfaces/vendors.interface';
import {
  AdaptMetadataValuesPipe,
  GetProfileTypeIconPipe,
  GetProfileTypeNamePipe,
  GetTrendIconColor,
  GetTrendIconPipe,
  PillFormatPipe,
  TransformMetadataValuesPipe,
  TransformProfilesPipe,
} from './pipes/vendors.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';

@Component({
  selector: 'itq-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    TransformProfilesPipe,
    FormatNumberPipe,
    GetUsernameInitialsPipe,
    GetProfileTypeIconPipe,
    AvatarComponent,
    ChartComponent,
    AdaptMetadataValuesPipe,
    PillFormatPipe,
    FAwesomeModule,
    CommonModule,
    MatRadioModule,
    TransformMetadataValuesPipe,
    GetTrendIconColor,
    FormsModule,
    GetTrendIconPipe,
    GetProfileTypeNamePipe,
    DecimalPipe,
    MatTooltipModule,
    ReactiveFormsModule,
    SvgComponent,
  ],
})
export class VendorsComponent implements OnInit {
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );

  public tableColumns: Array<TableColumn>;
  public dataSource: IProfileData;
  private subscriptions = new Subscription();
  public typesDataSource: Array<ProfileType> = [];
  public form: FormGroup;
  private users: Array<{ name: string; value: string }>;
  public showFilters = false;

  readonly ChartType = ChartType;
  readonly Sizes = Sizes;
  readonly WidgetActions = WidgetActions;
  readonly Align = Align;
  readonly PillType = PillType;
  readonly MetadataFields = MetadataFields;

  @ViewChild('updatedByTemplate') updatedByTemplate: TemplateRef<unknown>;
  @ViewChild('metadataTemplate') metadataTemplate: TemplateRef<unknown>;
  @ViewChild('trendTemplate') trendTemplate: TemplateRef<unknown>;
  @ViewChild('activityTemplate') activityTemplate: TemplateRef<unknown>;
  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;

  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private customOverlayService: CustomOverlayService,
    readonly profileMetricsService: ProfileMetricsService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly virtualScrollService: VirtualScrollService,
    readonly ecosystemsService: EcosystemsService,
  ) {}

  ngOnInit() {
    this.typesDataSource = this.profileService.types;
    this.initForm();
    this.getMetadata();
    this.onDataBound(this.initialState);
    this.bindChangeEcosystemSubscription();
    this.bindQueryParamsSubscription();
    this.bindDataSourceSubscription();
  }

  ngAfterViewInit(): void {
    this.profileService.getUsers().subscribe((response: Array<string>) => {
      this.users = response.map((item: string) => ({
        name: item,
        value: item,
      }));
      this.initTableColumns();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initForm(): void {
    this.form = new FormGroup({
      type: new FormControl(this.typesDataSource[0].id),
    });
  }

  public onChangeProfileType(): void {
    this.sharedService.loader$.next(true);
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    this.profileService
      .getMetadata(this.form.get('type').value)
      .subscribe(() => {
        this.initMetadata();
        this.onDataBound(this.initialState);
      });
  }

  private bindQueryParamsSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params: any) => {
        if (params?.profileId) {
          this.profileService
            .getProfileById(params.profileId)
            .subscribe((profile: Profile) => {
              this.onViewProfile(profile);
            });
        }
      }),
    );
  }

  private bindDataSourceSubscription(): void {
    this.subscriptions.add(
      this.profileService.getProfiles$.subscribe(() => {
        this.initialState = new QueryFilters(
          30,
          1,
          undefined,
          undefined,
          {
            direction: 'desc',
            active: 'updatedDate',
          },
          undefined,
        );
        this.onDataBound(this.initialState);
      }),
    );
  }

  private getMetadata(): void {
    this.profileService
      .getMetadata(this.typesDataSource[0].id)
      .subscribe(() => {
        this.initMetadata();
      });
  }

  public onCreate(profileTypeId: string): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      undefined,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileTypeId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: this.sharedService.laptop ? 'md' : 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.initialState.resetPagination().then(() => {
          this.virtualScrollService.dataBoundObservable.next();
        });
        this.onDataBound(this.initialState);
      }
    });
  }

  private initMetadata(): void {
    let metadataColumns = [];
    const profileTypeId = this.form.get('type').value;
    const profileTypeName =
      this.profileService.getProfileTypeById(profileTypeId)?.name;
    const columns = VISIBLE_COLUNNS.find((item: IMetadataField) => {
      return item.fieldName === profileTypeName;
    });
    if (columns?.columns?.length > 0) {
      columns.columns.forEach((column: IMetadataColumn) => {
        if (column.isMetric) {
          metadataColumns.push({
            name: column.name,
            dataKey: column.dataField,
            isSortable: true,
            isFilterable: column.isFilterable,
            customRender: true,
            template: this.metadataTemplate,
            templateData: column.dataField,
            searchFieldType: SearchFieldType.TEXT,
            width: column.width,
            position: column.position,
            httpBinding: false,
            isMetadata: true,
            show: column.show,
          });
        } else {
          const metadataItem = this.profileService.typeMetadata.find(
            (item: ProfileTypeMetadata) => item.name === column.dataField,
          );
          if (metadataItem) {
            metadataColumns.push({
              name: column.name,
              dataKey: column.dataField,
              searchField: `${metadataItem.id}.values`,
              isSortable: true,
              isFilterable: column.isFilterable,
              customRender: true,
              template: this.metadataTemplate,
              templateData: metadataItem.name,
              searchFieldType: SearchFieldType.TEXT,
              width: column.width,
              position: column.position,
              httpBinding: false,
              searchComponent: metadataItem.component,
              searchComponentType: column.searchComponentType,
              dataSource:
                metadataItem.component === ComponentType.DROPDOWN ||
                metadataItem.component === ComponentType.MULTIPLE_DROPDOWN
                  ? this.getMetadataValues(metadataItem)
                  : undefined,
              isMetadata: true,
              show: column.show,
            });
          }
        }
      });
    }
    this.initTableColumns(metadataColumns);
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemsService.changeEcosystem$.subscribe(() => {
        this.sharedService.loader$.next(true);
        this.initialState.resetPagination().then(() => {
          this.virtualScrollService.dataBoundObservable.next();
        });
        this.profileService
          .getMetadata(this.form.get('type').value)
          .subscribe(() => {
            this.initMetadata();
            this.onDataBound(this.initialState);
          });
      }),
    );
  }

  private initTableColumns(metadataColumns?: Array<TableColumn>): void {
    this.tableColumns = [
      {
        name: 'Updated',
        dataKey: 'updatedDate',
        searchField: 'updatedDate',
        isSortable: true,
        customRender: false,
        isFilterable: true,
        searchFieldType: SearchFieldType.DATE,
        searchComponent: ComponentType.DATE,
        width: '160px',
      },
      {
        name: 'By',
        dataKey: 'updatedBy',
        searchField: 'updatedBy',
        isSortable: true,
        position: 'center',
        isFilterable: true,
        customRender: true,
        httpBinding: false,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.DROPDOWN,
        template: this.updatedByTemplate,
        width: '180px',
        dataSource: this.users,
      },
      {
        name: 'Name',
        searchField: 'name',
        dataKey: 'name',
        isSortable: true,
        customRender: false,
        isFilterable: true,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
        width: '200px',
      },
      {
        name: 'Description',
        dataKey: 'description',
        searchField: 'description',
        isSortable: true,
        isFilterable: true,
        customRender: false,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
        cssClass: 'trim_line',
      },
      {
        name: 'vs Previous Week',
        dataKey: 'trend',
        searchField: 'trend',
        isSortable: true,
        isFilterable: false,
        customRender: true,
        template: this.trendTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
        width: '150px',
      },
      {
        name: 'Posts (7 Days)',
        dataKey: 'activity',
        searchField: 'activity',
        position: 'left',
        isSortable: true,
        customRender: true,
        template: this.activityTemplate,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
        width: '250px',
      },
      {
        name: '',
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
    if (metadataColumns) {
      this.tableColumns.splice(4, 0, ...metadataColumns);
    }
  }

  public onDataBound(params: QueryFilters): void {
    this.sharedService.loader$.next(true);
    this.initialState.addQueryColumn({
      searchValues: [this.form.get('type').value],
      searchField: 'typeId',
      searchFieldType: SearchFieldType.ID,
    });
    this.profileService
      .getProfiles(params, this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: IProfileData) => {
        this.dataSource = response;
        if (response.totalCount > 0) {
          const profileIds = response?.items?.map(
            (item: Profile) => item.profileId,
          );
          this.profileMetricsService
            .getActivityData(profileIds)
            .subscribe((response: Array<IWidgetData>) => {
              this.sharedService.loader$.next(false);
              this.dataSource.items = this.dataSource?.items?.map(
                (profile: ProfileList) => {
                  profile.activityData = response.find(
                    (data: IWidgetData) => data.widgetId === profile.profileId,
                  );
                  return profile;
                },
              );
            });
        } else {
          this.sharedService.loader$.next(false);
        }
      });
  }

  private getMetadataValues(
    metadataItem: ProfileMetadata,
  ): Array<IDropdownItem> {
    let result: Array<IDropdownItem> = [];
    const values = this.profileService.typeMetadata.find(
      (item: ProfileTypeMetadata) => item.id === metadataItem.id,
    )?.values as Array<string>;
    if (metadataItem.name === MetadataFields.CHEAT_REPUTATION_SCORE) {
      if (values.length > 0) {
        result = [
          ...result,
          ...values.map((subItem: string) => {
            const map = Cheat_Reputation_Map.find(
              (item: { name: string; value: number }) =>
                item.value === parseInt(subItem),
            );
            return {
              name: `${subItem} - ${map.name}`,
              value: subItem,
            };
          }),
        ];
      }
    } else {
      if (values.length > 0) {
        result = [
          ...result,
          ...values.map((subItem: string) => ({
            name: subItem,
            value: subItem,
          })),
        ];
      }
    }

    return result;
  }

  public onDelete(profileId: string): void {
    this.sharedService.loader$.next(true);
    const deleteSubscription = this.profileService
      .delete(profileId)
      .subscribe(() => {
        this.sharedService.loader$.next(false);
        deleteSubscription.unsubscribe();
        this.onDataBound(this.initialState);
        this.snackBar.open('Profile has been deleted!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
      });
  }

  public onView(profileId: string): void {
    const profile = this.profileService.findProfileById(profileId);
    this.onViewProfile(profile);
  }

  public onViewProfile(profile: Profile): void {
    const typeName = this.profileService.getProfileTypeById(
      profile.profileTypeId,
    )?.name;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.EXPLORE}_${profile.profileId}`,
      profile,
      WidgetActions.EXPLORE,
      undefined,
      new QueryFilters(
        30,
        1,
        undefined,
        undefined,
        {
          direction: 'desc',
          active: 'updatedDate',
        },
        undefined,
      ),
      new FormGroup({}),
      ProfileTypeIcon[typeName],
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}
