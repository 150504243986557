import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CoreModule, QueryFilters, Sizes, TableColumn } from '@intorqa-ui/core';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { DocumentItemTileV2Component } from '@portal/shared/components/document-item-tile-v2/document-item-tile-v2.component';
import { ISearchResults } from '@portal/shared/interfaces/document.interface';
import { DocumentItemService } from '@portal/shared/services/document-item.service';

@Component({
  selector: 'itq-raised-other-matches',
  templateUrl: './raised-other-matches.component.html',
  styleUrls: ['./raised-other-matches.component.scss'],
  standalone: true,
  imports: [CoreModule, DocumentItemTileV2Component],
})
export class RaisedOtherMatchesComponent implements OnInit {
  @Input() raisedAlertId: string;

  @Output() showNotifications = new EventEmitter<void>();

  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public matchesDataSource: ISearchResults;
  public tableColumns: TableColumn[] = [];

  @ViewChild('template')
  template: TemplateRef<unknown>;

  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly documentService: DocumentItemService,
  ) {}

  ngOnInit() {
    this.loadMatches();
  }

  ngAfterViewInit(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  public onDataBound(params: QueryFilters): void {
    this.initialState = params;
    this.loadMatches();
  }

  public loadMatches(): void {
    this.notificationsService.loader$.next(true);
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(this.initialState, this.raisedAlertId)
        .subscribe((matches: ISearchResults) => {
          this.documentService.toggleDocument$.next({
            documentId: undefined,
            isExpanded: false,
          });
          this.matchesDataSource = matches;
          this.notificationsService.loader$.next(false);
        });
    }
  }

  public onLoadNotifications(): void {
    this.showNotifications.emit();
  }
}
