import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  DayPipe,
  EventTeams,
  MonthAbbreviationPipe,
  YearPipe,
  Event,
} from '@intorqa-ui/core';
import { EventsService } from '@portal/shared/services/events.service';

@Component({
  selector: 'itq-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MonthAbbreviationPipe,
    YearPipe,
    DayPipe,
    MatIconModule,
  ],
})
export class EventComponent implements OnInit {
  @Input() events: Array<Event>;
  @Input() allowDrilldown = true;

  readonly EventTeams = EventTeams;

  public isExpanded = false;

  constructor(readonly eventService: EventsService) {}

  ngOnInit() {}

  public onClickEvent(event: Event): void {
    this.isExpanded = !this.isExpanded;
    this.eventService.drilldown$.next(event);
  }
}
