import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// Components
import { ContainerComponent } from './components/container/container.component';
import { CustomOverlayComponent } from './components/custom-overlay/custom-overlay.component';
import { FormElementComponent } from './components/form/form-element/form-element.component';
import { FormRowComponent } from './components/form/form-row/form-row.component';
import { FormComponent } from './components/form/form.component';
import { InputComponent } from './components/input/input.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatButtonComponent } from './components/mat-button/mat-button.component';
import { ModalBodyComponent } from './components/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from './components/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal/modal-header/modal-header.component';
import { NavButtonComponent } from './components/nav-button/nav-button.component';
import { PanelInfoComponent } from './components/panel-info/panel-info.component';
import { PillComponent } from './components/pill/pill.component';
import { SimpleInfoPanelComponent } from './components/simple-info-panel/simple-info-panel.component';
import { TableComponent } from './components/table/table.component';
import {
  DataPropertyGetterPipe,
  FilterByQueryPipe,
} from './components/table/table.pipe';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { VirtualTableComponent } from './components/virtual-table/virtual-table.component';

import { DialogComponent } from './components/dialog/dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { AngularModule } from './modules/angular.module';
import { FAwesomeModule } from './modules/font-awesome.module';
import { MaterialModule } from './modules/material.module';

import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ButtonThumbnailComponent } from './components/button-thumbnail/button-thumbnail.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { GetSelectedPipe } from './components/date-range/components/date-range-presets/date-range-presets.pipe';
import { DropdownResultsComponent } from './components/dropdown/dropdown-results/dropdown-results.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { GetLabelPipe } from './components/dropdown/dropdown.pipe';
import { HelpComponent } from './components/help/help.component';
import { InputToggleComponent } from './components/input-toggle/input-toggle.component';
import { MessageComponent } from './components/message/message.component';
import { MultipleDropdownItemComponent } from './components/multiple-dropdown/multiple-dropdown-item/multiple-dropdown-item.component';
import { MultipleDropdownResultsComponent } from './components/multiple-dropdown/multiple-dropdown-results/multiple-dropdown-results.component';
import { IsSelectionCheckedPipe } from './components/multiple-dropdown/multiple-dropdown-results/multiple-dropdown-results.pipe';
import { MultipleDropdownComponent } from './components/multiple-dropdown/multiple-dropdown.component';
import { GetHiddenSelectionsPipe } from './components/multiple-dropdown/multiple-dropdown.pipe';
import { PanelActionComponent } from './components/panel-action/panel-action.component';
import { SelectionListComponent } from './components/selection-list/selection-list.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { AutofocusDirective } from './directives/auto-focus.directive';
import { DynamicPlaceholderDirective } from './directives/dynamic-placeholder.directive';
import { GetSectionsPipe } from './pipes/category.pipe';
import { ConvertToUDSPipe } from './pipes/exchange-rate.pipe';
import {
  CastFormGroupPipe,
  ConvertFromEpochMillisPipe,
  DetectUrlPipe,
  IsArrayPipe,
  JsonParsePipe,
  MillisToDate,
  SafePipe,
  StripHtmlPipe,
  StripeImgPipe,
  TimeAgoPipe,
  UrlifyPipe,
} from './pipes/shared.pipe';
import {
  GetWidgetTooltipPipe,
  IsWidgetDisabledPipe,
} from './pipes/widget.pipe';
import { IsTagEnabledPipe } from './pipes/tag.pipe';
import { SvgComponent } from './components/svg/svg.component';
import { IconComponent } from './components/icon/icon.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CastIconNamePipe } from '@intorqa-ui/core';
@NgModule({
  imports: [
    MaterialModule,
    FAwesomeModule,
    AngularModule,
    RouterModule,
    YouTubePlayerModule,
    MillisToDate,
    SvgComponent,
    IconComponent,
    CastFormGroupPipe,
    CastIconNamePipe,
  ],
  declarations: [
    DialogComponent,
    CustomOverlayComponent,
    FormComponent,
    FormRowComponent,
    FormElementComponent,
    DropdownComponent,
    DropdownResultsComponent,
    PanelActionComponent,
    PanelInfoComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    DatePickerComponent,
    ModalFooterComponent,
    LoaderComponent,
    InputComponent,
    PillComponent,
    MatButtonComponent,
    TableComponent,
    SimpleInfoPanelComponent,
    ContainerComponent,
    VirtualTableComponent,
    UserAccountComponent,
    NavButtonComponent,
    DynamicPlaceholderDirective,
    AutofocusDirective,
    DataPropertyGetterPipe,
    ErrorComponent,
    MultipleDropdownComponent,
    MultipleDropdownResultsComponent,
    MultipleDropdownItemComponent,
    MessageComponent,
    SidebarComponent,
    TagsListComponent,
    ButtonThumbnailComponent,
    GetSelectedPipe,
    HelpComponent,
    SelectionListComponent,
    IsWidgetDisabledPipe,
    GetWidgetTooltipPipe,
    FilterByQueryPipe,
    GetSectionsPipe,
    InputToggleComponent,
    ButtonToggleComponent,
    IsSelectionCheckedPipe,
    GetHiddenSelectionsPipe,
    TooltipComponent,
    GetLabelPipe,
    IsArrayPipe,
    JsonParsePipe,
    ConvertFromEpochMillisPipe,
    ConvertToUDSPipe,
    YoutubePlayerComponent,
    UrlifyPipe,
    StripHtmlPipe,
    StripeImgPipe,
    TimeAgoPipe,
    SafePipe,
    DetectUrlPipe,
    IsTagEnabledPipe,
  ],
  exports: [
    FormComponent,
    FormRowComponent,
    FormElementComponent,
    DropdownComponent,
    DropdownResultsComponent,
    PanelInfoComponent,
    PanelActionComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    CustomOverlayComponent,
    LoaderComponent,
    InputComponent,
    DatePickerComponent,
    PillComponent,
    MatButtonComponent,
    TableComponent,
    SimpleInfoPanelComponent,
    ContainerComponent,
    VirtualTableComponent,
    UserAccountComponent,
    NavButtonComponent,
    DynamicPlaceholderDirective,
    AutofocusDirective,
    ErrorComponent,
    MultipleDropdownComponent,
    MultipleDropdownResultsComponent,
    MultipleDropdownItemComponent,
    MessageComponent,
    SidebarComponent,
    TagsListComponent,
    ButtonThumbnailComponent,
    GetSelectedPipe,
    SelectionListComponent,
    IsWidgetDisabledPipe,
    GetWidgetTooltipPipe,
    FilterByQueryPipe,
    GetSectionsPipe,
    InputToggleComponent,
    ButtonToggleComponent,
    IsSelectionCheckedPipe,
    TooltipComponent,
    IsArrayPipe,
    JsonParsePipe,
    ConvertFromEpochMillisPipe,
    ConvertToUDSPipe,
    YoutubePlayerComponent,
    UrlifyPipe,
    SafePipe,
    MillisToDate,
    TimeAgoPipe,
    StripeImgPipe,
    StripHtmlPipe,
    DetectUrlPipe,
    IsTagEnabledPipe,
  ],
  providers: [
    IsTagEnabledPipe,
    GetHiddenSelectionsPipe,
    ConvertToUDSPipe,
    UrlifyPipe,
    StripHtmlPipe,
    StripeImgPipe,
    TimeAgoPipe,
    SafePipe,
    DetectUrlPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor() {}
}
