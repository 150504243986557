<ng-container [formGroup]="form">
  <itq-sidebar
    class="widget_settings__left"
    [isExpanded]="expandedFilters"
    (toggle)="onToggleFilters()"
  >
    <itq-headline-simple
      class="px-7 my-4"
      [iconName]="navigationItem.icon"
      [iconType]="navigationItem.iconType"
      [iconSize]="'2x'"
      [title]="widget?.name"
      [description]="widget?.description"
    ></itq-headline-simple>
    <div class="filters__panel m-4">
      <itq-form-row class="search__type_container">
        <span class="mb-1 label font-semibold">Search type:</span>
        <itq-button-thumbnail
          formControlName="searchType"
          [dataSource]="searchTypes"
          (change)="onChangeQueryType()"
        ></itq-button-thumbnail>
      </itq-form-row>

      <itq-timeline-query
        [formControlName]="'query'"
        [required]="false"
        [navigationItem]="navigationItem"
        [queryType]="queryModel?.type"
        [query]="queryModel"
        [dataSource]="categoriesDataSource"
        [widget]="widget"
        [initialState]="initialState"
        (dataBound)="onDataBound($event, initialState)"
        (clearFilters)="onDataBound($event, initialState)"
      ></itq-timeline-query>
    </div>
  </itq-sidebar>
  <div class="widget_settings__right">
    <div class="widget_settings__toolbar">
      <itq-pill
        *ngIf="
          (this.queryModel.hasRules() || navigationItem?.rules?.length > 0) &&
          timelineData?.count > 0
        "
        [type]="PillType.CUSTOM"
        [class]="'secondary rounded-md'"
        >{{ timelineData.count }}</itq-pill
      >
      <div class="toolbar__container">
        <itq-mat-button
          [padding]="Sizes.SM"
          [materialStyle]="'basic'"
          [disabled]="!this.queryModel.hasRules() ? true : false"
          (clickEvent)="onAdd()"
        >
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="text-wrapper">Create tag</span>
        </itq-mat-button>
        <itq-toolbar
          [actions]="toolbarActions"
          [viewMode]="viewMode"
          [showTooltip]="true"
          [initialState]="initialState"
          [widget]="widget"
          [count]="timelineData?.count"
          (search)="onDataBound(this.queryModel, $event)"
          (toggleView)="onToggleView($event)"
          (refresh)="onDataBound(this.queryModel, this.initialState)"
          (add)="onAdd()"
        ></itq-toolbar>
        <itq-date-range
          [dates]="this.initialState?.where"
          (search)="onChangeDates($event)"
        ></itq-date-range>
      </div>
    </div>
    <div
      [ngClass]="{
        widget__container: true,
        ripl__mode: viewMode === ViewMode.RIPL
      }"
    >
      <ng-container
        *ngIf="
          this.queryModel.hasRules() || navigationItem?.rules?.length > 0;
          else noFiltersTemplate
        "
      >
        @if (timelineData) {
          <itq-timeline-feed
            class="h-full flex flex-col border border-border rounded-md flex-1"
            [widget]="widget"
            [data]="timelineData"
            [initialState]="initialState"
            [segment]="articleDetail"
            [query]="initialState?.query"
            [showFooter]="false"
            (drilldown)="onDrilldown($event)"
            (createProfile)="onCreateProfile($event)"
          ></itq-timeline-feed>
        }
      </ng-container>

      <ng-template #noFiltersTemplate>
        <itq-panel-action
          [label]="'No filters selected!'"
          [description]="
            'Type in the search box and add some filters for targeted results.'
          "
        ></itq-panel-action>
      </ng-template>
      <itq-document-item-detail
        *ngIf="articleDetail"
        [segment]="articleDetail"
        [viewParent]="true"
        [form]="form"
        [navigationItem]="navigationItem"
        [action]="navigationItem?.action"
        (drilldown)="onDrilldown($event)"
      ></itq-document-item-detail>
    </div>
  </div>
</ng-container>
<itq-error *ngIf="error?.stopPropagation" [error]="error"></itq-error>
