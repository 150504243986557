<div
  class="item-detail-container p-4 pl-0 cursor-pointer w-full"
  #itemdetailcontainer
  (click)="onExpand()"
>
  <div class="actions__container mr-4">
    @if (contentLanguage !== 'eng' && contentLanguage !== 'und') {
      <itq-mat-button
        class="ml-1"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [type]="'button'"
        [materialStyle]="'stroked'"
        [padding]="Sizes['X-SM']"
        [matTooltip]="'Translate'"
        (mousedown)="$event.stopImmediatePropagation()"
        (click)="$event.stopPropagation(); onTranslate()"
      >
        <fa-icon [icon]="['far', 'language']"></fa-icon>
      </itq-mat-button>
    }
    <itq-mat-button
      class="ml-1"
      [type]="'button'"
      [size]="Sizes.SM"
      [padding]="Sizes.SM"
      [materialStyle]="'stroked'"
      matTooltip="Share to slack"
      [matTooltipPosition]="'below'"
      (mousedown)="$event.stopImmediatePropagation()"
      (clickEvent)="shareSlack($event)"
    >
      <fa-icon [icon]="['fab', 'slack']"></fa-icon>
    </itq-mat-button>
    <itq-mat-button
      class="ml-1"
      [type]="'button'"
      [size]="Sizes.SM"
      [padding]="Sizes.SM"
      [materialStyle]="'stroked'"
      matTooltip="Share by email"
      [matTooltipPosition]="'below'"
      (mousedown)="
        $event.stopImmediatePropagation(); onShareEmail($event, document)
      "
    >
      <fa-icon [icon]="['far', 'envelope']"></fa-icon>
    </itq-mat-button>
    @if (allowDrilldown) {
      @if (document?.emitSource === 'Discord') {
        <itq-mat-button
          class="ml-1"
          [type]="'button'"
          [size]="Sizes.SM"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          matTooltip="View context"
          [matTooltipPosition]="'below'"
          (mousedown)="$event.stopImmediatePropagation()"
          (click)="$event.stopPropagation(); onDrilldownContext()"
        >
          <fa-icon [icon]="['far', 'list-timeline']"></fa-icon>
        </itq-mat-button>
      }
      @if (keycloakService.isUserInRole('saas-profiles')) {
        <itq-mat-button
          class="ml-1"
          [type]="'button'"
          [size]="Sizes.SM"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          matTooltip="Add note"
          [matTooltipPosition]="'below'"
          (mousedown)="$event.stopImmediatePropagation()"
          (clickEvent)="onAddNote($event)"
        >
          <fa-icon [icon]="['far', 'note']"></fa-icon>
        </itq-mat-button>
        @if (profile) {
          <itq-mat-button
            class="ml-1"
            [type]="'button'"
            [size]="Sizes.SM"
            [padding]="Sizes.SM"
            [materialStyle]="'stroked'"
            matTooltip="Add connection"
            [matTooltipPosition]="'below'"
            (mousedown)="$event.stopImmediatePropagation()"
            (clickEvent)="onAddConnection($event)"
          >
            <fa-icon [icon]="['far', 'code-merge']"></fa-icon>
          </itq-mat-button>
        }
      }

      <!-- @if (repliesCount > 0 && showReplies) {
        <itq-pill class="rounded-md" (select)="onDrilldownReplies()">
          {{
            repliesCount > 1
              ? repliesCount + ' replies'
              : repliesCount + ' reply'
          }}
        </itq-pill>
      } -->
    }
  </div>
  <div class="container__header pb-4 overflow-hidden">
    <div class="header_icon rounded-md bg-black">
      <img
        [src]="'assets/icons/' + document?.emitSource?.toLowerCase() + '.svg'"
      />
    </div>
    <div class="header__content overflow-hidden">
      <div class="items-center flex overflow-hidden">
        @if (allowDrilldown) {
          @if (document.emitChannel) {
            <span
              class="text-link font-semibold cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis"
              (mousedown)="
                $event.stopImmediatePropagation();
                onDrilldown(SegmentScope.CHANNEL)
              "
            >
              <span
                [innerHTML]="
                  document.emitChannel | highlightSearch: query?.toString()
                "
              ></span>
            </span>
          }
          @if (keycloakService.isUserInRole('saas-profiles')) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'right' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [width]="220"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.CHANNEL"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        } @else {
          @if (document.emitChannel) {
            <span
              class="font-semibold"
              [innerHTML]="
                document.emitChannel | highlightSearch: query?.toString()
              "
            ></span>
          }
        }
      </div>
      <div class="content_title text-xs">
        <span
          [innerHTML]="document.emitSource | highlightSearch: query?.toString()"
        ></span>
        <span
          [innerHTML]="document.emitType | highlightSearch: query?.toString()"
        ></span>
      </div>
    </div>
  </div>
  <div class="container__content p-4 flex-col rounded-md">
    <div class="container__content_wrapper">
      <div
        class="content-headline font-semibold"
        [innerHTML]="
          translatedTitle && translatedTitle !== ''
            ? (translatedTitle | highlightSearch: query?.toString())
            : (document.emitHeadline | highlightSearch: query?.toString())
        "
      ></div>
      <div class="subheadline-when text-xs pt-2 whitespace-nowrap flex-wrap">
        <div class="flex">
          {{ document.emitDocumentDate | date: DateFormats.short }}
        </div>
        &nbsp;({{ getDocumentDate(document.emitDocumentDate) | timeAgo }})<span
          >&nbsp; by&nbsp;</span
        >
        @if (allowDrilldown) {
          <div
            class="text-link font-semibold cursor-pointer whitespace-nowrap overflow-hidden text-ellipsis block max-w-[150px]"
            (mousedown)="
              $event.stopImmediatePropagation(); onDrilldown(SegmentScope.ACTOR)
            "
          >
            <span
              [innerHTML]="
                document.emitActor | highlightSearch: query?.toString()
              "
            ></span>
          </div>
          @if (keycloakService.isUserInRole('saas-profiles')) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'right' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [width]="220"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.ACTOR"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        } @else {
          <div>
            <span
              class="font-semibold"
              [innerHTML]="
                document.emitActor | highlightSearch: query?.toString()
              "
            ></span>
          </div>
        }
      </div>
      <p
        [ngClass]="{
          'text-xs content-body': true,
          'line-clamp-3': !isExpanded
        }"
        [innerHTML]="
          translatedContent && translatedContent !== ''
            ? (translatedContent | highlightSearch: query?.toString())
            : (document.emitBodyText
              | striphtml
              | stripImg
              | highlightSearch: query?.toString())
        "
      ></p>
    </div>

    @if (isExpanded) {
      @if (document.emitURL) {
        <div class="flex flex-col">
          <div class="flex my-4 flex-col">
            <span class="mb-1 text-13 font-semibold">LINK</span>
            <a
              class="text-link text-xs"
              rel="noreferrer"
              [attr.href]="document.emitURL"
              target="_blank"
              [innerHTML]="document.emitURL"
            ></a>
          </div>
        </div>
      }
      @if (document?.emitExtensionFields?.length > 0) {
        <itq-document-extension-fields
          [query]="query"
          [document]="document"
          [allowDrilldown]="false"
          (drilldown)="onDrilldownDiscord($event)"
        >
        </itq-document-extension-fields>
      }
      @if (contentLinks?.length > 0) {
        <div class="flex my-4 flex-col">
          <div class="mb-1 text-13 font-semibold">
            <fa-icon
              [matTooltip]="
                'Links that have been automatically extracted from the text of this post'
              "
              [matTooltipPosition]="'above'"
              [icon]="['far', 'info-circle']"
            ></fa-icon>
            EXTRACTED LINKS
          </div>
          <ul>
            <li *ngFor="let link of contentLinks">
              <a
                class="text-link text-xs"
                [href]="link.href"
                target="_blank"
                [innerHTML]="link.href | highlightSearch: query?.toString()"
              ></a>
            </li>
          </ul>
        </div>
      }

      <ng-container *ngFor="let media of document.emitMedia">
        @if (media?.media_source?.toLowerCase() === 'youtube') {
          <div class="flex my-4 flex-col">
            <span class="mb-1 text-13 font-medium">VIDEO</span>
            <iframe
              [src]="media.media_link | safe"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        }
      </ng-container>
    }
  </div>
  <!-- @if (showReplies) {
    <itq-mat-button
      [type]="'button'"
      [materialStyle]="'mini-fab'"
      matTooltip="View full document"
      [matTooltipPosition]="'below'"
      (mousedown)="$event.stopImmediatePropagation()"
      (clickEvent)="isExpanded = !isExpanded"
    >
      <fa-icon
        [icon]="['far', isExpanded ? 'chevrons-up' : 'chevrons-down']"
      ></fa-icon>
    </itq-mat-button>
  } -->
</div>

<ng-template #profileTemplate let-data>
  <div
    class="menu__container flex"
    (mouseleave)="showNestedNavbar = false; showListProfile = false"
  >
    <div class="menu__wrapper w-full">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="showNestedNavbar = true; showListProfile = false"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
            Create new profile
          </div>
          <fa-icon
            [icon]="[
              'far',
              showNestedNavbar ? 'chevron-right' : 'chevron-down'
            ]"
          ></fa-icon>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="
          onMouseOverLinkProfile(
            data === ProfileDrildownScope.ACTOR
              ? TagCategory.Actor
              : TagCategory.Channel
          )
        "
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
            <div class="flex items-center">Add existing profile</div>
          </div>
          <fa-icon
            [icon]="['far', showListProfile ? 'chevron-right' : 'chevron-down']"
          ></fa-icon>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="showListProfile = false; showNestedNavbar = false"
        (clickEvent)="
          onDrilldown(
            data === ProfileDrildownScope.ACTOR
              ? SegmentScope.ACTOR
              : SegmentScope.CHANNEL
          )
        "
      >
        <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
        Explore {{ data === ProfileDrildownScope.ACTOR ? 'Actor' : 'Channel' }}
      </itq-mat-button>
    </div>
    @if (showListProfile) {
      <div class="menu__sidebar">
        <itq-dropdown-results
          [dataSource]="profilesDataSource"
          [httpBinding]="true"
          [dataFields]="{ name: 'name', value: 'profileId' }"
          [initialState]="profileInitialState"
          (dataBound)="onProfileDataBound($event)"
          (changeValue)="
            onLinkProfile(
              $event,
              data === ProfileDrildownScope.ACTOR
                ? ProfileDrildownScope.ACTOR
                : ProfileDrildownScope.CHANNEL
            )
          "
        ></itq-dropdown-results>
      </div>
    }
    @if (showNestedNavbar) {
      <div class="menu__sidebar" [ngStyle]="{ width: '170px' }">
        @for (item of profileTypesDataSource; track item.id) {
          <itq-mat-button
            [size]="Sizes.BLOCK"
            [align]="Align.START"
            [type]="'button'"
            [materialStyle]="'basic'"
            [padding]="Sizes['X-SM']"
            (clickEvent)="
              onCreateProfile(
                data === ProfileDrildownScope.ACTOR
                  ? ProfileDrildownScope.ACTOR
                  : ProfileDrildownScope.CHANNEL,
                item
              )
            "
          >
            <fa-icon
              [icon]="['far', item?.name | getProfileTypeIcon]"
            ></fa-icon>
            <span>
              {{ item?.name }}
            </span>
          </itq-mat-button>
        }
      </div>
    }
  </div>
</ng-template>
