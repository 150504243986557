import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  DayPipe,
  Event,
  EventTeams,
  MonthAbbreviationPipe,
  TimeFormatPipe,
  TimelineOrientation,
  YearPipe,
} from '@intorqa-ui/core';
import { EventComponent } from '../event/event.component';
import {
  GroupEventsByDatePipe,
  GroupEventsByTeamPipe,
} from '@portal/shared/pipes/events.pipe';

@Component({
  selector: 'itq-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MonthAbbreviationPipe,
    YearPipe,
    DayPipe,
    TimeFormatPipe,
    EventComponent,
    GroupEventsByDatePipe,
    GroupEventsByTeamPipe,
  ],
})
export class EventItemComponent implements OnInit {
  @Input() events: Array<Event>;
  @Input() orientation = TimelineOrientation.VERTICAL;
  @Input() segment: any;
  @Input() allowDrilldown = true;

  readonly TimelineOrientation = TimelineOrientation;
  readonly EventTeams = EventTeams;

  constructor() {}

  ngOnInit() {}
}
